import {
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_COMPLETED,
  STATUS_CANCELED,
} from "../../constants/reclamations";
export default {
  RECLAMATIONS_LIST: "Reclamations list",
  ADD_RECLAMATION: "Add reclamation",
  VIEW_RECLAMATION: "Reclamation",
  RECLAMATION_ADDED: "Reclamation added",
  EDIT_RECLAMATION: "Edit reclamation",
  RECLAMATION_UPDATED: "Reclamation updated",
  DELETE_THIS_RECLAMATION: "Delete this reclamation ?",
  RECLAMATION_DELETED: "Reclamation deleted",
  STATUS: "Status",
  MOTIF: "Reason",
  OTHER_MOTIF: "Other reason",
  EXCERPT: "Déscription",
  ATTACHEMENTS: "Attachments",
  CANCEL_RECLAMATION: "Cancel",
  CANCEL_RECLAMATION_QUESTION: "Cancel this reclamation ?",
  COMPLETE_RECLAMATION: "Complete",
  COMPLETE_RECLAMATION_QUESTION: "Complete this reclamation ?",
  RECLAMATION_CANCELED: "Reclamation canceled",
  RECLAMATION_COMPLETED: "Reclamation completed",
  ALL_STATUS: "All status",
  VIEW_RECLAMATION: "Reclamation",
  STATUS_DRAFT : "Draft",
  STATUS_PENDING : "Pending",
  STATUS_COMPLETED : "Completed",
  STATUS_CANCELED : "Canceled",
  MOTIF_VEHICLE_DAMAGED : "Vehicle damaged",
  MOTIF_INVALID_PAYMENT : "Invalid payment",
  MOTIF_OTHER : "Other",
  MOTIF_END_ACCEPTED_SELECT : "Accept",
  MOTIF_END_REFUSED_SELECT : "Refuse",
  MOTIF_END_ACCEPTED : "Accepted",
  MOTIF_END_REFUSED : "Refused",
  DESCRIPTION_END : "Reason",
};
