export default {
  APP_NAME: "WISE Manager",
  EMAIL: "Email",
  PASSWORD: "Password",
  SIGN_IN: "Sign In",
  FORGOT_PASSWORD: "Forgot Password?",
  PAGE_NOT_FOUND: "Requested Page Not Found",
  RETURN_HOME: "Return to Home",
  WELCOME: "Welcome",
  MY_PROFILE: "My Profile",
  LOGOUT: "Logout",
  DASHBOARD: "Dashboard",
  USERS: "Users",
  ROLES: "Roles",
  ORGANIZATIONS: "Organizations",
  ORGANIZATION: "Organization",
  PRODUCTS: "Products",
  PRODUCT: "Product",
  TRANSACTIONS: "Transactions",
  TRANSACTION: "Transaction",
  ALL: "All",
  NAME: "Name",
  ADDRESS: "Address",
  PHONE: "Phone",
  CREATED_AT: "Created At",
  DESCRIPTION: "Description",
  SEARCH: "Search",
  PER_PAGE: "Per Page",
  VIEW: "View",
  EDIT: "Edit",
  DELETE: "Delete",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES: "Displaying {from} to {to} of {of} entries",
  X_LINES_SELECTED: "{count} selected lines",
  RETURN_TO_LIST: "Return to List",
  SELECT_IMAGE: "Select Image",
  CHANGE_IMAGE: "Change Image",
  REMOVE_IMAGE: "Remove Image",
  ROLE: "Role",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  CONFIRM_PASSWORD: "Confirm Password",
  RESELLERS: "Resellers",
  RESELLER: "Reseller",
  RECLAMATIONS: "Reclamations",
  RECLAMATION: "Reclamation",
  COMMENTS: "Comments",
  COMMENT: "Comment",
  LOCALE: "Locale",
  LOCALE_FR: "French",
  LOCALE_EN: "English",
  GLOBAL: "Global",
  NONE: "None",
  SUBMIT: "Submit",
  UPDATED_AT: "Updated At",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "All Organizations",
  ALL_RESELLERS: "All Resellers",
  ALL_ROLES: "All Roles",
  ALL_USERS: "All Users",
  ALL_PRODUCTS: "All Products",
  EXCERPT: "Description",
  OWNER: "Owner",
  USER: "User",
  YES: "Yes",
  NO: "No",
  OK: "OK",
  PICTURE: "Picture",
  GALLERY: "Gallery",
  TAXES: "Taxes",
  PRICE: "Price",
  RATE: "Rate (%)",
  CODE: "Code",
  DESIGNATION: "Designation",
  DEFAULT_TAXES: "Taxes",

  NUMERO_SAAQ: "Numéro SAAQ",
  INVOICES: "Invoices",
  INVOICE: "Invoice",
  RECIPIENT: "Recipient",
  COUNTRY: "Country",
  COUNTRIES: "Countries",
  STATES: "States",
  STATE: "State",
  CITY: "City",
  ZIPCODE: "Zipcode",
  NOTE: "Note",
  STATUS: "Status",
  ALL_STATUSES: "All statuses",
  AMOUNT: "Amount",
  PAIED: "Paid",
  TOTAL: "Total",
  TOTAL_PAIED: "Total paid",
  CREATED_BY: "Created by",
  DETAILS: "Details",
  PAYMENTS: "Payments",
  UNIT_PRICE: "Unit price",
  SUBTOTAL: "Subtotal",
  QUANTITY: "Quantity",
  DISCOUNT: "Discount",
  REMAINING_PAYMENT_AMOUNT: "Remaining payment amount",
  DISCOUNT_PERCENT: "Discount (%)",
  CLOSE: "Close",
  CUSTOMER: "Customer",
  CUSTOMERS: "Customers",
  LOGS: "Logs",
  CAUSER: "Causer",
  DATE: "Date",
  GO_BACK: "Go back",
  PACKAGE: "Package",
  PACKAGES: "Packages",
  ALL_PACKAGES: "All packages",
  SUBSCRIPTION: "Subscription",
  SUBSCRIPTIONS: "Subscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Percentage",
  TAX_TYPE_AMOUNT: "Amount",
  PAYMENT: "Payment",
  ALL_INVOICES: "All invoices",
  DISCOUNTS: "Discounts",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Discard changes and leave page?",
  COMPANY_NAME: "Company name",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Individual",
  BILLING_ENTITY_TYPE_COMPANY: "Company",

  CONTACTS: "Contacts",
  CONTACT: "Contact",
  SUPPLIER: "Supplier",
  SCHOOL: "School",

  TITLE: "Title",
  TITLES: "Titles",
  ALL_TITLES: "All titles",
  TITLE_MR: "Mister",
  TITLE_MDM: "Madam",
  TAGS: "Tags",
  BIRTHDAY: "Birthday",

  SUPPLIERS: "Suppliers",
  FISCAL_NUMBER: "Fiscal number",
  SAVE: "",

  SUCCESS_VERIFICATION:
    "Congratulations! You have successfully verified your security code.",
  ERROR_VERIFICATION: "The verification code is incorrect",
  VERIFICATION_CODE: "Verification code",
  DELIVERY_INFORMATIONS: "Delivery Information",
  RESEND_CODE: "Resend the code",
  ENABLE_2FA:
    "Do you want to enable two-step verification to further secure your account?",
  DISABLE_2FA:
    "Do you want to disable two-step verification and go back to 2FA-free login?",

  IMPORTS: "Imports",
  IMPORT: "Import",
  NEXT_STEP: "Next step",
  PREV_STEP: "Previous step",
  CANCEL: "Cancel",
  DOWNLOAD_CSV_FILE: "Download csv file",

  DEALER_NAME: "Dealer Name",
  TPS_TAXES: "TPS Taxes",
  TVQ_TAXES: "TVQ Taxes",
  MERCHAND_TYPE: "Merchand type",
  CON_TYPE_GROS: "Concessionnaire occasion et grossiste",
  CON_TYPE_NEW: "Concessionnaire véhicule neuf",
  INVITATIONS: "Invitations",
  ACCEPT: "Accept",

  STARTED_AT: "Start At",
  ENDED_AT: "End At",
  REC_TYPE: "Recurent",
  OTH_TYPE: "Other",
  EVENTS: "Events",
  DRAFT: "Draft",
  COMPLETED: "Completed",
  VALIDATE: "Valider",
  INVALIDATE: "Invalider",
  VALIDATED: "Validated",

  NEXT: "Suivant",
  PREVIOUS: "Prédent",
  ACTIONS: "Actions",
  TEMPLATES_MAILS: "Mail models",
  ago: "il y a {0}",
};
